@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

/*
top
*/
$PAGE_ID: top;
$PATH: '../';
$COMMON_PATH: '#{$PATH}img/common/';
$IMAGE_PATH: '#{$PATH}img/#{$PAGE_ID}/';

/* ==[ .p-top-hero ]==================================== */
.p-top-hero {
  .sec-inner {
    background-color: map_get(variables.$COLOR, gray);
    padding: 30px 0 0;

    @include mixin.tabletP {
      padding: 0;
    }
  }
}

/* ==[ .p-top-mv ]==================================== */
.p-top-mv {
  position: relative;

  .swiper-container {
    padding-bottom: 50px;
  }

  .swiper-button-prev {
    left: calc(20% - 50px);
  }

  .swiper-button-next {
    right: calc(20% - 50px);
  }

  .swiper-slide-active {
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
  }

}

/* ==[ .p-top-news ]==================================== */
.p-top-news {
  padding-top: 30px;

  .txt-link {
    margin-left: auto;
  }

  .p-container-lg {
    .container-fluid {
      margin-right: 0;
    }
  }

  .left-col {
    width: 100px;

    @include mixin.tabletP {
      width: 100%;
    }
  }

  .right-col {
    width: calc(100% - 100px);

    @include mixin.tabletP {
      width: 100%;
    }
  }

  .p-sp-block {

    @include mixin.tabletP {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .txt-link {
      display: none;

      @include mixin.tabletP {
        display: inline-block;
      }
    }
  }
}

/* ==[ .p-top-info ]==================================== */
.p-top-info {

  .info-card-wrap {

    +.info-card-wrap {

      @include mixin.tabletP {
        margin-top: 20px;
      }
    }
  }

  .info-card {
    border-radius: 16px;
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, .1);
    text-decoration: none;

    &:hover {
      box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, .3);
      color: variables.$COLOR_TEXT;
    }

    .card-img {
      background-color: #E5E4E5;
      border-radius: 16px 16px 0 0;
      overflow: hidden;

      &.ratio {
        &::before {
          padding-top: 51.3888%;
        }

        img {
          width: 100%;
          height: auto;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .card-body {
      padding: 20px;

      @include mixin.tabletP {
        padding: 15px;
      }
    }

    .cnp-txt {
      color: #707070;
      margin-bottom: 10px;
    }

    .card-title {
      font-size: calc(16px + 2 * (100vw - 320px) / 1600);
      font-weight: 700;
      line-height: normal;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .card-footer {
      padding: 20px;

      @include mixin.tabletP {
        padding: 15px;
      }
    }

    .period {
      color: map_get(variables.$COLOR, dark-gray);
    }
  }
}

/* ==[ .p-top-search ]==================================== */
.p-top-search {
  color: white;

  /* .sec-inner {
    padding-top: 0;
  } */

  .c-ttl-unit {
    margin-bottom: 0;
    padding: 0 30px;
  }

  .container-full-outer {
    position: relative;
    overflow: hidden;

    .search-bg-img {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;

      &[src$=".svg"] {
        width: 100%;
        max-width: 100%;
      }
    }

    .c-ttl-unit {
      margin-bottom: 30px;
    }
  }

  .container-full {
    background: rgb(255,63,5);
    background: linear-gradient(45deg, rgba(255,63,5,1) 0%, rgba(223,18,26,1) 80%);
    border-radius: 67px;
    padding: 60px 0;
    z-index: 0;

    @include mixin.tabletL {
      border-radius: 40px;
    }

    @include mixin.tabletP {
      border-radius: 20px;
    }
  }

  .container-fluid {
    position: relative;
    z-index: 2;
  }

  .input-group {
    background-color: white;
    border-radius: 30px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, .1) inset;
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin: 0 auto;
    width: 760px;
    max-width: 100%;

    .form-control {
      background-color: transparent;
      border: none;
      border-radius: 0;
      padding: 0 20px;
      height: 60px;

      &:focus {
        outline: none !important;
      }
    }

    .input-group-text {
      background-color: transparent;
      border: none;

      img {
        width: 28px;
        height: 28px;
      }
    }
  }

  .p-link-block {

    .ttl-ja {
      @include mixin.tabletP {
        margin-bottom: 10px;
      }
    }
  }

  .custom-btn-wrap {
    margin-top: 30px;

    .custom-btn {
      border-radius: 16px;
      box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);

      &.bg-transparent {
        background-color: transparent;
        padding: 0;
        width: auto;
      }
    }

  }
}

/* ==[ .p-top-ranking ]==================================== */
.p-top-ranking {

  .ttl-ja {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
      border: 1px map_get(variables.$COLOR, yellow) solid;
      color: map_get(variables.$COLOR, yellow);
      content: "タイプ別";
      display: inline-block;
      font-size: calc(12px + 2 * (100vw - 320px) / 1600);
      padding: 0 5px;
      margin-right: 5px;
    }
  }

  .card-title {
    border-bottom: 2px map_get(variables.$COLOR, yellow) solid;
    color: map_get(variables.$COLOR, yellow);
    font-size: calc(18px + 6 * (100vw - 320px) / 1600);
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;

    @include mixin.tabletL {
      width: 100%;
    }
  }

  .ranking-card-unit {
    border-bottom: 1px map_get(variables.$COLOR, dark-gray) dashed;

    @include mixin.tabletL {
      border-bottom: none;
      width: calc(100% / 3 - 10px);
    }

    padding-bottom: 20px;

    +.ranking-card-unit {
      margin-top: 30px;

      @include mixin.tabletL {
        margin-top: 0;
      }
    }
  }

  .ranking-card {
    flex-direction: row;

    @include mixin.tabletL {
      flex-direction: column;
    }

    .card-img {
      width: 150px;

      @include mixin.tabletL {
        width: 100%;
      }

      &.rank_1,
      &.rank_2,
      &.rank_3 {
        position: relative;

        &::before {
          content: "";
          display: block;
          width: 30px;
          height: 24px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;

          @include mixin.tabletP {
            width: 22px;
            height: 16px;
          }
        }
      }

      &.rank_1 {
        &::before {
          background: url("#{mixin.set_common_path('icon_ranking1.svg')}") no-repeat center center;
          background-size: 100% auto;
        }
      }

      &.rank_2 {
        &::before {
          background: url("#{mixin.set_common_path('icon_ranking2.svg')}") no-repeat center center;
          background-size: 100% auto;
        }
      }

      &.rank_3 {
        &::before {
          background: url("#{mixin.set_common_path('icon_ranking3.svg')}") no-repeat center center;
          background-size: 100% auto;
        }
      }

      img {
        position: relative;
        z-index: 0;
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 15px;
      width: calc(100% - 150px);

      @include mixin.tabletL {
        padding-left: 0;
        width: 100%;
      }

      .card-header {
        flex-grow:1;

        @include mixin.tabletL {
          margin-bottom: 10px;
        }
      }

      .card-footer {
        margin-top: auto;
        width: 100%;
      }
    }
  }

  .prov-apply {
    text-align: center;
    margin-top: 30px;
  }
}

/* ==[ .p-top-lineup ]==================================== */
.p-top-lineup {
  background-color: map_get(variables.$COLOR, gray);

  .sec-inner {
    padding: 60px 0;

    @include mixin.tabletP {
      padding: 40px 0;
    }
  }

  .swiper-container-wrapper {
    padding-bottom: 15px;
  }

  .ttl-sub-ja {
    line-height: 1;

    span {
      display: inline-block;
      padding: 0 30px;
      position: relative;

      &::before,
      &::after {
        content: "";
        display: block;
        width: 20px;
        height: 16px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &::before {
        background: url("#{mixin.set_common_path('icon_slash_left.svg')}") no-repeat center center;
        background-size: 100% auto;
        left: 0;
      }

      &::after {
        background: url("#{mixin.set_common_path('icon_slash_right.svg')}") no-repeat center center;
        background-size: 100% auto;
        right: 0;
      }
    }
  }

  .swiper-container-wrapper {

    .swiper-button-prev {
      left: 15px;
    }

    .swiper-button-next {
      right: 15px;
    }
  }

  .swiper-slide {
    padding: 0 90px;

    @include mixin.tabletP {
      padding: 0;
    }

    &.swiper-slide-active {

      .lineup-card{
        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .lineup-card {
    background-color: white;
    gap: 0 30px;
    padding: 15px;
    flex-direction: row;

    @include mixin.tabletP {
      gap: 15px 0;
      flex-direction: column;
    }

    .card-img {
      width: 40%;

      @include mixin.tabletP {
        width: 100%;
      }

      &.ratio {
        padding-top: 66.1765%;
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
      width: 60%;

      @include mixin.tabletP {
        width: 100%;
      }

      .p-detail-list {
        margin-bottom: 30px;
      }

      .price {
        &::after {
          content: "万円(税込)";
        }
      }

      .card-footer {
        margin-top: auto;
        text-align: right;

        @include mixin.tabletP {
          margin-top: 20px;
        }
      }

    }

  }
}

/* ==[ .p-top-reserve ]==================================== */
.p-top-reserve {

  .sec-inner {
    padding: 100px 0 60px;

    @include mixin.tabletP {
      padding: 80px 0 40px;
    }
  }

  .container-full-outer {
    color: white;
    position: relative;
    overflow: hidden;

    .reserve-bg-img {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;

      &[src$=".svg"] {
        width: 100%;
        max-width: 100%;
      }
    }

    .c-ttl-unit {
      margin-bottom: 10px;
    }
  }

  .container-full {
    background: rgb(255,63,5);
    background: linear-gradient(45deg, rgba(255,63,5,1) 0%, rgba(223,18,26,1) 80%);
    border-radius: 67px;
    padding: 60px 0;
    z-index: 0;

    @include mixin.tabletL {
      border-radius: 40px;
    }

    @include mixin.tabletP {
      border-radius: 20px;
    }
  }

  .container-fluid {
    position: relative;
    z-index: 2;
  }

  .p-form-item {

    +.p-form-item {
      margin-top: 30px;
    }

    .p-form-error__message {
      text-align: center;
      font-size: calc(18px + 2 * (100vw - 320px) / 1600);
    }

    .form-ttl {
      color: variables.$COLOR_TEXT;
      margin-bottom: 10px;
      text-align: center;
    }

    .bg-gray {
      border-radius: 46px;
      padding: 30px 15px;

      @include mixin.tabletL {
        border-radius: 40px;
      }

      @include mixin.tabletP {
        border-radius: 20px;
      }
    }

    .form-row {
      margin: 0 auto;
      width: 824px;
      max-width: 100%;

      @include mixin.tabletP {
        width: 100%;
      }

      .p-select-unit {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 15px 30px;
        width: 100%;

        @include mixin.tabletP {
          flex-wrap: wrap;
        }

        &__item {
          text-align: center;
          width: calc(100% / 2);

          @include mixin.tabletP {
            width: 100%;
          }
        }

        .custom-select {
          width: 500px;
          max-width: 100%;
        }
      }
    }

    &.p-form-step3,
    &.p-form-step4 {
      .bg-gray {
        color: #000;
      }
      .form-row {
        width: 100%;
      }
    }
  }

  .tbl-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 10px;
    width: 990px;
    max-width: 100%;

    @include mixin.tabletP {
      flex-wrap: wrap;
    }

    &__item {
      width: auto;

      @include mixin.tabletP {
        width: calc(100% / 2);
      }

      &:first-child {
        @include mixin.tabletP {
          order: 2;
        }
      }

      &:nth-child(2) {
        @include mixin.tabletP {
          width: 100%;
          order: 1;
        }
      }

      &:last-child {
        @include mixin.tabletP {
          order: 3;
        }
      }
    }

    .chose-ttl-unit {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0 30px;

      @include mixin.tabletP {
        justify-content: flex-start;
        gap: 0 15px;
        margin-bottom: 10px;
        flex-wrap: wrap;
      }

      li {
        color: variables.$COLOR_TEXT;
        font-size: calc(16px + 2 * (100vw - 320px) / 1600);
        font-weight: 700;
        line-height: normal;

        &::before {
          display: inline-block;
        }

        &.chose-shop {
          &::before {
            content: "店舗：";
          }
        }

        &.chose-service {
          &::before {
            content: "サービス：";
          }
        }
      }
    }
  }

  .p-card-block {
    gap: 0 20px;

    @include mixin.tabletP {
      gap: 24px 0;
      flex-wrap: wrap;
    }

    .c-card {
      background-color: white;
      border-radius: 16px;
      box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
      gap: 10px 0;
      padding: 36px 15px;

      .card-img {
        text-align: center;

        img {
          width: auto;
        }
      }

      .card-body {
        text-align: center;
      }

      .card-title {
        font-size: calc(16px + 2 * (100vw - 320px) / 1600);
        font-weight: 700;
      }
    }
  }
}

/* ==[ .p-top-appli ]==================================== */
.p-top-appli {
  .sec-inner {
    padding: 60px 0 120px;

    @include mixin.tabletP {
      padding: 40px 0 80px;
    }
  }

  .c-ttl-unit {
    margin-bottom: 0;

    @include mixin.tabletP {
      margin-bottom: 5px;
    }
  }

  .ttl-sub-ja {
    font-size: calc(13px + 11 * (100vw - 320px) / 1600);
  }
}

/* ==[ .p-top-voice ]==================================== */
.p-top-voice {
  background-color: map_get(variables.$COLOR, gray);

  .sec-inner {
    padding: 80px 0;

    @include mixin.tabletP {
      padding: 60px 0;
    }

    /* padding: 0 0 80px;

    @include mixin.tabletP {
      padding: 0 0 60px;
    } */
  }

  .p-voice-unit {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @include mixin.tabletP {
      flex-wrap: wrap;
    }
  }

  .p-voice-wrap {
    padding: 8px 0;
    position: relative;
    width: 100%;
    height: 100%;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 22px;
      height: 18px;
      position: absolute;
      z-index: 1;
    }

    &::before {
      background: url(#{mixin.set_common_path('icon_quotation_top.svg')});
      background-size: 100% auto;
      top: 0;
      left: 15px;
    }

    &::after {
      background: url(#{mixin.set_common_path('icon_quotation_bottom.svg')});
      background-size: 100% auto;
      bottom: 0;
      right: 15px;
    }
  }

  .voice-card {
    background-color: transparent;
    border-radius: 14px;
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    overflow: hidden;

    &:hover {
      box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
      color: variables.$COLOR_TEXT;
      opacity: 1;
    }

    .card-img {

      &.ratio {
        &::before {
          padding-top: 54.1666%;
        }
      }
    }
    .card-body,
    .card-footer {
      background-color: white;
      padding: 20px;
    }

    .card-footer {
      .days {
        color: map_get(variables.$COLOR, dark-gray);
      }
    }
  }
}

/* ==[ .p-top-sns ]==================================== */
.p-top-sns {
  /* background-color: map_get(variables.$COLOR, gray); */

  .sec-inner {
    padding: 60px 0;

    @include mixin.tabletP {
      padding: 40px 0;
    }
  }

  .c-ttl-unit {
    margin-bottom: 0;
    padding: 0 30px;
  }

  .p-yt-card-unit {
    display: flex;
    flex-direction: column;
    gap: 20px 0;
  }

  .yt-card {
    flex-direction: row;
    text-decoration: none;

    &:hover {
      color: variables.$COLOR_TEXT;
      opacity: .7;
    }

    .card-img {
      background-color: variables.$COLOR_TEXT;
      width: 270px;

      @include mixin.tabletL {
        width: 160px;
      }

      @include mixin.tabletP {
        width: 270px;
      }

      @include mixin.sp {
        width: 100px;
      }

      &.ratio {
        &::before {
          padding-top: 65.1851%;
        }
      }

      img {
        height: auto;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }
    }
    .card-body {
      padding: 20px 15px;
      width: calc(100% - 270px);

      @include mixin.tabletL {
        width: calc(100% - 160px);
      }

      @include mixin.tabletP {
        width: calc(100% - 270px);
      }

      @include mixin.sp {
        width: calc(100% - 100px);
      }
    }

    .card-title {
      font-size: calc(14px + 4 * (100vw - 320px) / 1600);
      font-weight: 700;
      line-height: normal;
    }
  }

  .p-ig-list {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mixin.tabletP {
      margin-top: 40px;
    }

    &__item {
      width: calc(100% / 3 - 6px);

      a {
        width: 100%;
        display: block;
        position: relative;
        overflow: hidden;

        &::before {
          content: "";
          display: block;
          /* padding-top: 130.435%; */
          padding-top: 100%;
        }

      }
    }

    &__img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .sns-btn {
    display: block;
    margin: 30px auto 0;
    width: 300px;
    max-width: 100%;
  }

  .c-balloon-ttl {
    margin-top: 50px;

    &::before {

      background-color: white;
    }
  }

  .sns-link-list {
    display: flex;
    gap: 0 18px;

    @include mixin.tabletP {
      gap: 18px;
      flex-wrap: wrap;
    }

    li {
      width: calc(100% / 4);

      @include mixin.tabletP {
        width: calc(100% / 2 - 9px);
      }

      .sns-link {
        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
        width: 100%;

        img {
          width: 100%;
        }

        &:hover {
          box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}

/* ==[ .p-top-shop ]==================================== */
.p-top-shop {

  .sec-inner {
    padding: 120px 0 80px;

    @include mixin.tabletP {
      padding: 80px 0 60px;
    }
  }

  .p-shop-card-unit {
    display: flex;
    gap: 30px 18px;
    justify-content: space-between;
    flex-wrap: nowrap;

    @include mixin.tabletP {
      flex-wrap: wrap;
    }
  }

  .p-card-wrap {
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden;
    width: calc(100% / 3);

    @include mixin.tabletP {
      width: 100%;
    }
  }

  .shop-card {

    .card-inner {
      padding: 15px 15px 30px;
    }

    .card-header {
      background-color: variables.$COLOR_MAIN;
      text-align: center;
    }

    .card-title {
      color: white;
      margin-bottom: 0;
      padding: 10px 15px;
    }

    .card-img {
      border-radius: 16px;
      overflow: hidden;

      &.ratio {
        &::before {
          padding-top: 67.7777%;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .card-body {
      padding: 15px 0;
    }

    .card-footer {
      @include mixin.tabletP {
        padding: 0 20px;
      }
    }

    [class^="btn"] {

      +[class^="btn"] {
        margin-top: 10px;
      }
    }

  }

  .p-address-unit {

    li {
      line-height: normal;
      padding-left: 26px;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      +li {
        margin-top: 10px;
      }

      &.p-address {

        &::before {
          background: url(#{mixin.set_image_path('top/shop/icon_mapping.svg')}) no-repeat center center;
          background-size: 100% auto;
          height: 22px;
          top: 3px;
          transform: translateY(0);
        }
      }

      &.p-open {

        &::before {
          background: url(#{mixin.set_image_path('top/shop/icon_clock.svg')}) no-repeat center center;
          background-size: 100% auto;
          height: 16px;
        }
      }

      &.p-tel {

        &::before {
          background: url(#{mixin.set_image_path('top/shop/icon_phone.svg')}) no-repeat center center;
          background-size: 100% auto;
          height: 16px;
        }

        a {
          &:hover {
            color: map_get(variables.$COLOR, blue);
            text-decoration: underline;
            opacity: 1;
          }
        }
      }
    }
  }

  .contact-unit {
    margin-top: 30px;

    .contact-link {
      border-radius: 16px;
      box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
      display: block;
      overflow: hidden;

      &:hover {
        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

/* ==[ .p-top-guide ]==================================== */
.p-top-guide {
  background-color: map_get(variables.$COLOR, gray);

  .sec-inner {
    padding: 60px 0;

    @include mixin.tabletP {
      padding: 40px 0;
    }
  }

  .ttl-main-ja {
    color: map_get(variables.$COLOR, green);

    &::before {
      background: url(#{mixin.set_image_path('top/guide/guide_ttl_icon.svg')}) no-repeat center center;
      background-size: 100% auto;
      content: "";
      display: block;
      margin: 0 auto;
      width: 35px;
      height: 55px;
    }
  }

  .c-contxt {
    text-align: center;

    @include mixin.tabletP {
      text-align: left;
    }
  }

  .p-card-block {
    margin-bottom: 50px;

    &.type3 {
      gap: 0 18px;

      @include mixin.tabletP {
        gap: 20px 0;
      }

      .c-card {
        border-radius: 16px;
        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
        overflow: hidden;

        img {
          width: 100%;
        }
      }
    }
  }

  .guide-unit-top {
    display: flex;
    gap: 10px 30px;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 30px;

    @include mixin.tabletL {
      gap: 10px 15px;
    }

    @include mixin.tabletP {
      gap: 20px 0;
      flex-wrap: wrap;
    }

    &__item {
      width: calc(100% / 3);

      &:first-child {

        .guide-card {

          span {
            &::before {
              background: url(#{mixin.set_image_path('top/guide/2023/guide_icon1.svg')}) no-repeat center center;
              background-size: 100% auto;
            }
          }
        }
      }

      &:nth-child(2) {

        .guide-card {

          span {
            &::before {
              background: url(#{mixin.set_image_path('top/guide/2023/guide_icon2.svg')}) no-repeat center center;
              background-size: 100% auto;
            }
          }
        }
      }

      &:nth-child(3) {

        .guide-card {

          span {
            &::before {
              background: url(#{mixin.set_image_path('top/guide/2023/guide_icon3.svg')}) no-repeat center center;
              background-size: 100% auto;
            }
          }
        }
      }

      @include mixin.tabletP {
        width: 100%;
      }

      .guide-card {
        background-color: white;
        border-radius: 50px;
        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
        display: block;
        padding: 16px;
        text-align: center;
        text-decoration: none;
        width: 100%;

        span {
          font-size: calc(14px + 2 * (100vw - 320px) / 1600);
          font-weight: 700;
          padding-left: 31px;
          position: relative;

          &::before {
            content: "";
            display: block;
            width: 26px;
            height: 26px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }

        &:hover {
          box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
          color: variables.$COLOR_TEXT;
          opacity: 1;
        }

        /* .card-img {
          margin-bottom: 10px;

          img {
            width: auto;
          }
        } */

        .card-body {
          display: flex;
          flex-direction: column;
        }

        .card-title {
          /* display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; */
          line-height: normal;
          margin-bottom: 0;

        }
      }
    }
  }

  .guide-unit-bottom {
    display: flex;
    gap: 10px 20px;
    justify-content: space-between;
    flex-wrap: nowrap;

    @include mixin.tabletL {
      gap: 10px 15px;
    }

    @include mixin.tabletP {
      gap: 20px 0;
      flex-wrap: wrap;
    }

    &__item {
      width: calc(100% / 2);

      &:first-child {

        .guide-link {

          span {
              &::before {
                background: url(#{mixin.set_image_path('top/guide/2023/guide_icon4.svg')}) no-repeat center center;
                background-size: 100% auto;
            }
          }
        }
      }

      &:nth-child(2) {

        .guide-link {

          span {
            &::before {
              background: url(#{mixin.set_image_path('top/guide/2023/guide_icon5.svg')}) no-repeat center center;
              background-size: 100% auto;
            }
          }
        }
      }

      @include mixin.tabletP {
        width: 100%;
      }

      .guide-link {
        background-color: white;
        border-radius: 50px;
        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
        display: block;
        padding: 16px;
        text-align: center;
        text-decoration: none;
        width: 100%;

        &:hover {
          box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
          color: variables.$COLOR_TEXT;
          opacity: 1;
        }

        span {
          font-size: calc(14px + 2 * (100vw - 320px) / 1600);
          font-weight: 700;
          padding-left: 31px;
          position: relative;

          &::before {
            content: "";
            display: block;
            width: 26px;
            height: 26px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }

        &.insurance {
          span {
            &::before {
              background: url(#{mixin.set_image_path('top/guide/guide_icon5.svg')}) no-repeat center center;
              background-size: 100% auto;
              transform: translateY(calc(-50% + 3px));
            }
          }
        }

        &.faq {
          span {
            &::before {
              background: url(#{mixin.set_image_path('top/guide/guide_icon6.svg')}) no-repeat center center;
              background-size: 100% auto;
            }
          }
        }
      }
    }
  }

  .c-balloon-ttl {
    &::before {

      background-color: map_get(variables.$COLOR, gray);
    }
  }
}

/* ==[ .p-top-ad ]==================================== */
.p-top-ad {
  .sec-inner {
    padding: 60px 0;

    @include mixin.tabletP {
      padding: 40px 0;
    }
  }

  .swiper-container-wrapper {
    padding: 0 80px;

    @include mixin.tabletP {
      padding: 0;
    }

    .swiper-wrapper-inner {
      overflow: hidden;
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-next {
      right: 0;
    }
  }
}

/* ==[ .p-top-aboutus ]==================================== */
.p-top-aboutus {
  background: url(#{mixin.set_image_path('top/aboutus/aboutus_bg.jpg')}) no-repeat center top;
  background-size: cover;

  @include mixin.tabletP {
    background: url(#{mixin.set_image_path('top/aboutus/aboutus_bg_sp.jpg')}) no-repeat center top;
    background-size: cover;
  }

  .sec-inner {
    padding: 120px 0;

    @include mixin.tabletP {
      padding: 80px 0;
    }
  }

  .ttl-main-en,
  .c-contxt {
    color: white;
  }

  .btn-unit {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @include mixin.tabletP {
      flex-direction: column;
    }
  }
}

/* 20240711追加 */
.myBlockSearch {
  background: rgb(255,63,5);
  background: linear-gradient(45deg, rgba(255,63,5,1) 0%, rgba(223,18,26,1) 80%);
  border-radius: 67px;
  margin-top: 50px;
  padding-top: 15px;
  position: relative;

  @include mixin.tabletL {
    border-radius: 40px;
  }

  @include mixin.tabletP {
    border-radius: 20px;
    margin-top: 30px;
  }
  &__outer {
    overflow: hidden;
    position: relative;

  }

  &__header {
    margin-top: -100px;
    padding: 0 30px;
    text-align: center;
    margin-top: -55px;
    width: 100%;
    max-width: 100%;

    @include mixin.tabletP {
      margin-top: -52px;
    }

    /* img {
      @include mixin.tabletP {
        width: 100%;
      }
    } */
  }

  &__inner {
    background-color: #FCE7E7;
    padding: 40px 0 70px;
  }

  .modFlex {
    gap: 0 32px;
    justify-content: center;

    @include mixin.tabletP {
      flex-wrap: wrap;
      gap: 30px 0;
    }

    &__item {
      text-align: center;
      width: calc(100% / 3);

      @include mixin.tabletP {
        width: 100%;
      }
    }
  }
}

.myBlockFMNews {
  background-color: variables.$COLOR_TEXT;
  color: white;
  overflow: hidden;
  position: relative;

  .row {
    align-items: center;
  }

  &__bg {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;

    &[src$=".svg"] {
      width: 100%;
      max-width: 100%;
    }
  }

  .modTitle {
    text-align: left;

    &__en {
      font-size: calc(30px + 20 * (100vw - 320px) / 1600);
      font-family: variables.$FONT_ALPHA;
      font-weight: 800;
    }

    &__ja {
      font-size: calc(14px + 2 * (100vw - 320px) / 1600);
      font-weight: 700;
    }
  }

  .modLeadText {
    margin-bottom: 50px;

    @include mixin.tabletP {
      margin-bottom: 20px;
    }
  }

  .btn {
    margin: 0;

    @include mixin.tabletL{
      margin: 0 auto;
    }
  }

  .sec-inner {
    position: relative;
    z-index: 2;
  }

  .myBlockPopup {
    flex-wrap: wrap;
    align-items: start;
    gap: 20px;
    position: relative;

    @include mixin.tabletL {
      margin-bottom: 30px;
    }
  }

  .modPopup {
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.16);
    background-color: white;
    border-radius: 12px;
    display: none;
    align-items: start;
    gap: 0 24px;
    padding: 24px 20px;
    width: calc(500 / 1920 * 100vw);
    max-width: 500px;
    min-width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    @include mixin.tabletL {
      width: calc(500 / 992 * 100vw);
      max-width: 500px;
      min-width: 300px;
    }

    @include mixin.tabletP {
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 0 15px;
      padding: 20px 15px;
      width: 100%;
      min-width: auto;
      max-width: 100%;
      position: static;
      top: auto;
      left: auto;
      transform: translate(0, 0);
    }

    &__img {
      text-align: center;
      width: 35%;

      img {
        border: 1px #909090 solid;
      }
    }

    &__body {
      width: 65%;

      .btn {
        font-size: calc(14px + 2 * (100vw - 320px) / 1600);
        padding: 10px;
      }
    }

    &__title,
    &__text {
      color: variables.$COLOR_TEXT;
    }

    &__title {
      font-size: calc(16px + 8 * (100vw - 320px) / 1600);

      &.-new {
        display: flex;
        align-items: center;
        gap: 0 10px;

        &::before {
          content: "NEW";
          background-color: variables.$COLOR_MAIN;
          color: white;
          font-family: variables.$FONT_ALPHA;
          font-size: calc(12px + 4 * (100vw - 320px) / 1600);
          font-weight: 800;
          padding: 0 5px;
        }
      }
    }

    &__text {
      margin-bottom: 30px;

      @include mixin.tabletP {
        margin-bottom: 20px;
      }
    }
  }

  .popupCard {
    background-color: transparent;
    border: none;
    width: calc(100% / 3 - 14px);
    transition: .4s ease-in-out;

    @include mixin.tabletP {
      width: 100%;
    }

    &:hover {
      @media screen and (min-width: 768px) {

        cursor: pointer;

        >.modPopup {
        display: flex;
        }
      }
    }

    &:nth-child(3n+1) {
      .modPopup {
        left: 0;
        transform: translate(0, -50%);

        @include mixin.tabletP {
          left: auto;
          transform: translate(0, 0);
        }
      }
    }

    &:nth-child(3n) {
      .modPopup {
        left: auto;
        right: 0;
        transform: translate(0, -50%);

        @include mixin.tabletP {
          right: auto;
          transform: translate(0, 0);
        }
      }
    }

    .card-body {
      display: flex;
      gap: 10px 0;
      justify-content: center;
      flex-direction: column;

      @include mixin.tabletP {
        display: none;
      }
    }

    .card-img {
      border: 1px #909090 solid;
    }

    .card-title {
      font-size: calc(14px + 8 * (100vw - 320px) / 1600);
      text-align: center;

      &::before {
        content: "";
        display: inline-block;
      }

      &.-new {
        display: flex;
        align-items: center;
        gap: 0 5px;

        &::before {
          content: "NEW";
          background-color: variables.$COLOR_MAIN;
          font-family: variables.$FONT_ALPHA;
          font-size: calc(12px + 2 * (100vw - 320px) / 1600);
          font-weight: 800;
          padding: 0 4px;
          min-width: calc(46px + 6 * (100vw - 320px) / 1600);
        }
      }
    }
  }

}
