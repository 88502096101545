@charset "UTF-8";
/* 余白 8の倍数 */
/*
top
*/
/* ==[ .p-top-hero ]==================================== */
.p-top-hero .sec-inner {
  background-color: #EDEDED;
  padding: 30px 0 0;
}
@media not all and (min-width: 768px) {
  .p-top-hero .sec-inner {
    padding: 0;
  }
}

/* ==[ .p-top-mv ]==================================== */
.p-top-mv {
  position: relative;
}
.p-top-mv .swiper-container {
  padding-bottom: 50px;
}
.p-top-mv .swiper-button-prev {
  left: calc(20% - 50px);
}
.p-top-mv .swiper-button-next {
  right: calc(20% - 50px);
}
.p-top-mv .swiper-slide-active {
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
}

/* ==[ .p-top-news ]==================================== */
.p-top-news {
  padding-top: 30px;
}
.p-top-news .txt-link {
  margin-left: auto;
}
.p-top-news .p-container-lg .container-fluid {
  margin-right: 0;
}
.p-top-news .left-col {
  width: 100px;
}
@media not all and (min-width: 768px) {
  .p-top-news .left-col {
    width: 100%;
  }
}
.p-top-news .right-col {
  width: calc(100% - 100px);
}
@media not all and (min-width: 768px) {
  .p-top-news .right-col {
    width: 100%;
  }
}
@media not all and (min-width: 768px) {
  .p-top-news .p-sp-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.p-top-news .p-sp-block .txt-link {
  display: none;
}
@media not all and (min-width: 768px) {
  .p-top-news .p-sp-block .txt-link {
    display: inline-block;
  }
}

/* ==[ .p-top-info ]==================================== */
@media not all and (min-width: 768px) {
  .p-top-info .info-card-wrap + .info-card-wrap {
    margin-top: 20px;
  }
}
.p-top-info .info-card {
  border-radius: 16px;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
.p-top-info .info-card:hover {
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
  color: #333;
}
.p-top-info .info-card .card-img {
  background-color: #E5E4E5;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
}
.p-top-info .info-card .card-img.ratio::before {
  padding-top: 51.3888%;
}
.p-top-info .info-card .card-img.ratio img {
  width: 100%;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
}
.p-top-info .info-card .card-body {
  padding: 20px;
}
@media not all and (min-width: 768px) {
  .p-top-info .info-card .card-body {
    padding: 15px;
  }
}
.p-top-info .info-card .cnp-txt {
  color: #707070;
  margin-bottom: 10px;
}
.p-top-info .info-card .card-title {
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  font-weight: 700;
  line-height: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.p-top-info .info-card .card-footer {
  padding: 20px;
}
@media not all and (min-width: 768px) {
  .p-top-info .info-card .card-footer {
    padding: 15px;
  }
}
.p-top-info .info-card .period {
  color: #aaa;
}

/* ==[ .p-top-search ]==================================== */
.p-top-search {
  color: white;
  /* .sec-inner {
    padding-top: 0;
  } */
}
.p-top-search .c-ttl-unit {
  margin-bottom: 0;
  padding: 0 30px;
}
.p-top-search .container-full-outer {
  position: relative;
  overflow: hidden;
}
.p-top-search .container-full-outer .search-bg-img {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.p-top-search .container-full-outer .search-bg-img[src$=".svg"] {
  width: 100%;
  max-width: 100%;
}
.p-top-search .container-full-outer .c-ttl-unit {
  margin-bottom: 30px;
}
.p-top-search .container-full {
  background: #ff3f05;
  background: linear-gradient(45deg, #ff3f05 0%, #df121a 80%);
  border-radius: 67px;
  padding: 60px 0;
  z-index: 0;
}
@media not all and (min-width: 992px) {
  .p-top-search .container-full {
    border-radius: 40px;
  }
}
@media not all and (min-width: 768px) {
  .p-top-search .container-full {
    border-radius: 20px;
  }
}
.p-top-search .container-fluid {
  position: relative;
  z-index: 2;
}
.p-top-search .input-group {
  background-color: white;
  border-radius: 30px;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.1) inset;
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin: 0 auto;
  width: 760px;
  max-width: 100%;
}
.p-top-search .input-group .form-control {
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0 20px;
  height: 60px;
}
.p-top-search .input-group .form-control:focus {
  outline: none !important;
}
.p-top-search .input-group .input-group-text {
  background-color: transparent;
  border: none;
}
.p-top-search .input-group .input-group-text img {
  width: 28px;
  height: 28px;
}
@media not all and (min-width: 768px) {
  .p-top-search .p-link-block .ttl-ja {
    margin-bottom: 10px;
  }
}
.p-top-search .custom-btn-wrap {
  margin-top: 30px;
}
.p-top-search .custom-btn-wrap .custom-btn {
  border-radius: 16px;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
}
.p-top-search .custom-btn-wrap .custom-btn.bg-transparent {
  background-color: transparent;
  padding: 0;
  width: auto;
}

/* ==[ .p-top-ranking ]==================================== */
.p-top-ranking .ttl-ja {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.p-top-ranking .ttl-ja::before {
  border: 1px #E8B300 solid;
  color: #E8B300;
  content: "タイプ別";
  display: inline-block;
  font-size: calc(12px + 2 * (100vw - 320px) / 1600);
  padding: 0 5px;
  margin-right: 5px;
}
.p-top-ranking .card-title {
  border-bottom: 2px #E8B300 solid;
  color: #E8B300;
  font-size: calc(18px + 6 * (100vw - 320px) / 1600);
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}
@media not all and (min-width: 992px) {
  .p-top-ranking .card-title {
    width: 100%;
  }
}
.p-top-ranking .ranking-card-unit {
  border-bottom: 1px #aaa dashed;
  padding-bottom: 20px;
}
@media not all and (min-width: 992px) {
  .p-top-ranking .ranking-card-unit {
    border-bottom: none;
    width: calc(33.3333333333% - 10px);
  }
}
.p-top-ranking .ranking-card-unit + .ranking-card-unit {
  margin-top: 30px;
}
@media not all and (min-width: 992px) {
  .p-top-ranking .ranking-card-unit + .ranking-card-unit {
    margin-top: 0;
  }
}
.p-top-ranking .ranking-card {
  flex-direction: row;
}
@media not all and (min-width: 992px) {
  .p-top-ranking .ranking-card {
    flex-direction: column;
  }
}
.p-top-ranking .ranking-card .card-img {
  width: 150px;
}
@media not all and (min-width: 992px) {
  .p-top-ranking .ranking-card .card-img {
    width: 100%;
  }
}
.p-top-ranking .ranking-card .card-img.rank_1, .p-top-ranking .ranking-card .card-img.rank_2, .p-top-ranking .ranking-card .card-img.rank_3 {
  position: relative;
}
.p-top-ranking .ranking-card .card-img.rank_1::before, .p-top-ranking .ranking-card .card-img.rank_2::before, .p-top-ranking .ranking-card .card-img.rank_3::before {
  content: "";
  display: block;
  width: 30px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
@media not all and (min-width: 768px) {
  .p-top-ranking .ranking-card .card-img.rank_1::before, .p-top-ranking .ranking-card .card-img.rank_2::before, .p-top-ranking .ranking-card .card-img.rank_3::before {
    width: 22px;
    height: 16px;
  }
}
.p-top-ranking .ranking-card .card-img.rank_1::before {
  background: url("../img/common/icon_ranking1.svg") no-repeat center center;
  background-size: 100% auto;
}
.p-top-ranking .ranking-card .card-img.rank_2::before {
  background: url("../img/common/icon_ranking2.svg") no-repeat center center;
  background-size: 100% auto;
}
.p-top-ranking .ranking-card .card-img.rank_3::before {
  background: url("../img/common/icon_ranking3.svg") no-repeat center center;
  background-size: 100% auto;
}
.p-top-ranking .ranking-card .card-img img {
  position: relative;
  z-index: 0;
}
.p-top-ranking .ranking-card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 15px;
  width: calc(100% - 150px);
}
@media not all and (min-width: 992px) {
  .p-top-ranking .ranking-card .card-body {
    padding-left: 0;
    width: 100%;
  }
}
.p-top-ranking .ranking-card .card-body .card-header {
  flex-grow: 1;
}
@media not all and (min-width: 992px) {
  .p-top-ranking .ranking-card .card-body .card-header {
    margin-bottom: 10px;
  }
}
.p-top-ranking .ranking-card .card-body .card-footer {
  margin-top: auto;
  width: 100%;
}
.p-top-ranking .prov-apply {
  text-align: center;
  margin-top: 30px;
}

/* ==[ .p-top-lineup ]==================================== */
.p-top-lineup {
  background-color: #EDEDED;
}
.p-top-lineup .sec-inner {
  padding: 60px 0;
}
@media not all and (min-width: 768px) {
  .p-top-lineup .sec-inner {
    padding: 40px 0;
  }
}
.p-top-lineup .swiper-container-wrapper {
  padding-bottom: 15px;
}
.p-top-lineup .ttl-sub-ja {
  line-height: 1;
}
.p-top-lineup .ttl-sub-ja span {
  display: inline-block;
  padding: 0 30px;
  position: relative;
}
.p-top-lineup .ttl-sub-ja span::before, .p-top-lineup .ttl-sub-ja span::after {
  content: "";
  display: block;
  width: 20px;
  height: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.p-top-lineup .ttl-sub-ja span::before {
  background: url("../img/common/icon_slash_left.svg") no-repeat center center;
  background-size: 100% auto;
  left: 0;
}
.p-top-lineup .ttl-sub-ja span::after {
  background: url("../img/common/icon_slash_right.svg") no-repeat center center;
  background-size: 100% auto;
  right: 0;
}
.p-top-lineup .swiper-container-wrapper .swiper-button-prev {
  left: 15px;
}
.p-top-lineup .swiper-container-wrapper .swiper-button-next {
  right: 15px;
}
.p-top-lineup .swiper-slide {
  padding: 0 90px;
}
@media not all and (min-width: 768px) {
  .p-top-lineup .swiper-slide {
    padding: 0;
  }
}
.p-top-lineup .swiper-slide.swiper-slide-active .lineup-card {
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
}
.p-top-lineup .lineup-card {
  background-color: white;
  gap: 0 30px;
  padding: 15px;
  flex-direction: row;
}
@media not all and (min-width: 768px) {
  .p-top-lineup .lineup-card {
    gap: 15px 0;
    flex-direction: column;
  }
}
.p-top-lineup .lineup-card .card-img {
  width: 40%;
}
@media not all and (min-width: 768px) {
  .p-top-lineup .lineup-card .card-img {
    width: 100%;
  }
}
.p-top-lineup .lineup-card .card-img.ratio {
  padding-top: 66.1765%;
}
.p-top-lineup .lineup-card .card-body {
  display: flex;
  flex-direction: column;
  width: 60%;
}
@media not all and (min-width: 768px) {
  .p-top-lineup .lineup-card .card-body {
    width: 100%;
  }
}
.p-top-lineup .lineup-card .card-body .p-detail-list {
  margin-bottom: 30px;
}
.p-top-lineup .lineup-card .card-body .price::after {
  content: "万円(税込)";
}
.p-top-lineup .lineup-card .card-body .card-footer {
  margin-top: auto;
  text-align: right;
}
@media not all and (min-width: 768px) {
  .p-top-lineup .lineup-card .card-body .card-footer {
    margin-top: 20px;
  }
}

/* ==[ .p-top-reserve ]==================================== */
.p-top-reserve .sec-inner {
  padding: 100px 0 60px;
}
@media not all and (min-width: 768px) {
  .p-top-reserve .sec-inner {
    padding: 80px 0 40px;
  }
}
.p-top-reserve .container-full-outer {
  color: white;
  position: relative;
  overflow: hidden;
}
.p-top-reserve .container-full-outer .reserve-bg-img {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.p-top-reserve .container-full-outer .reserve-bg-img[src$=".svg"] {
  width: 100%;
  max-width: 100%;
}
.p-top-reserve .container-full-outer .c-ttl-unit {
  margin-bottom: 10px;
}
.p-top-reserve .container-full {
  background: #ff3f05;
  background: linear-gradient(45deg, #ff3f05 0%, #df121a 80%);
  border-radius: 67px;
  padding: 60px 0;
  z-index: 0;
}
@media not all and (min-width: 992px) {
  .p-top-reserve .container-full {
    border-radius: 40px;
  }
}
@media not all and (min-width: 768px) {
  .p-top-reserve .container-full {
    border-radius: 20px;
  }
}
.p-top-reserve .container-fluid {
  position: relative;
  z-index: 2;
}
.p-top-reserve .p-form-item + .p-form-item {
  margin-top: 30px;
}
.p-top-reserve .p-form-item .p-form-error__message {
  text-align: center;
  font-size: calc(18px + 2 * (100vw - 320px) / 1600);
}
.p-top-reserve .p-form-item .form-ttl {
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}
.p-top-reserve .p-form-item .bg-gray {
  border-radius: 46px;
  padding: 30px 15px;
}
@media not all and (min-width: 992px) {
  .p-top-reserve .p-form-item .bg-gray {
    border-radius: 40px;
  }
}
@media not all and (min-width: 768px) {
  .p-top-reserve .p-form-item .bg-gray {
    border-radius: 20px;
  }
}
.p-top-reserve .p-form-item .form-row {
  margin: 0 auto;
  width: 824px;
  max-width: 100%;
}
@media not all and (min-width: 768px) {
  .p-top-reserve .p-form-item .form-row {
    width: 100%;
  }
}
.p-top-reserve .p-form-item .form-row .p-select-unit {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 15px 30px;
  width: 100%;
}
@media not all and (min-width: 768px) {
  .p-top-reserve .p-form-item .form-row .p-select-unit {
    flex-wrap: wrap;
  }
}
.p-top-reserve .p-form-item .form-row .p-select-unit__item {
  text-align: center;
  width: 50%;
}
@media not all and (min-width: 768px) {
  .p-top-reserve .p-form-item .form-row .p-select-unit__item {
    width: 100%;
  }
}
.p-top-reserve .p-form-item .form-row .p-select-unit .custom-select {
  width: 500px;
  max-width: 100%;
}
.p-top-reserve .p-form-item.p-form-step3 .bg-gray, .p-top-reserve .p-form-item.p-form-step4 .bg-gray {
  color: #000;
}
.p-top-reserve .p-form-item.p-form-step3 .form-row, .p-top-reserve .p-form-item.p-form-step4 .form-row {
  width: 100%;
}
.p-top-reserve .tbl-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 10px;
  width: 990px;
  max-width: 100%;
}
@media not all and (min-width: 768px) {
  .p-top-reserve .tbl-header {
    flex-wrap: wrap;
  }
}
.p-top-reserve .tbl-header__item {
  width: auto;
}
@media not all and (min-width: 768px) {
  .p-top-reserve .tbl-header__item {
    width: 50%;
  }
}
@media not all and (min-width: 768px) {
  .p-top-reserve .tbl-header__item:first-child {
    order: 2;
  }
}
@media not all and (min-width: 768px) {
  .p-top-reserve .tbl-header__item:nth-child(2) {
    width: 100%;
    order: 1;
  }
}
@media not all and (min-width: 768px) {
  .p-top-reserve .tbl-header__item:last-child {
    order: 3;
  }
}
.p-top-reserve .tbl-header .chose-ttl-unit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 30px;
}
@media not all and (min-width: 768px) {
  .p-top-reserve .tbl-header .chose-ttl-unit {
    justify-content: flex-start;
    gap: 0 15px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
}
.p-top-reserve .tbl-header .chose-ttl-unit li {
  color: #333;
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  font-weight: 700;
  line-height: normal;
}
.p-top-reserve .tbl-header .chose-ttl-unit li::before {
  display: inline-block;
}
.p-top-reserve .tbl-header .chose-ttl-unit li.chose-shop::before {
  content: "店舗：";
}
.p-top-reserve .tbl-header .chose-ttl-unit li.chose-service::before {
  content: "サービス：";
}
.p-top-reserve .p-card-block {
  gap: 0 20px;
}
@media not all and (min-width: 768px) {
  .p-top-reserve .p-card-block {
    gap: 24px 0;
    flex-wrap: wrap;
  }
}
.p-top-reserve .p-card-block .c-card {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
  gap: 10px 0;
  padding: 36px 15px;
}
.p-top-reserve .p-card-block .c-card .card-img {
  text-align: center;
}
.p-top-reserve .p-card-block .c-card .card-img img {
  width: auto;
}
.p-top-reserve .p-card-block .c-card .card-body {
  text-align: center;
}
.p-top-reserve .p-card-block .c-card .card-title {
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  font-weight: 700;
}

/* ==[ .p-top-appli ]==================================== */
.p-top-appli .sec-inner {
  padding: 60px 0 120px;
}
@media not all and (min-width: 768px) {
  .p-top-appli .sec-inner {
    padding: 40px 0 80px;
  }
}
.p-top-appli .c-ttl-unit {
  margin-bottom: 0;
}
@media not all and (min-width: 768px) {
  .p-top-appli .c-ttl-unit {
    margin-bottom: 5px;
  }
}
.p-top-appli .ttl-sub-ja {
  font-size: calc(13px + 11 * (100vw - 320px) / 1600);
}

/* ==[ .p-top-voice ]==================================== */
.p-top-voice {
  background-color: #EDEDED;
}
.p-top-voice .sec-inner {
  padding: 80px 0;
  /* padding: 0 0 80px;

  @include mixin.tabletP {
    padding: 0 0 60px;
  } */
}
@media not all and (min-width: 768px) {
  .p-top-voice .sec-inner {
    padding: 60px 0;
  }
}
.p-top-voice .p-voice-unit {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
@media not all and (min-width: 768px) {
  .p-top-voice .p-voice-unit {
    flex-wrap: wrap;
  }
}
.p-top-voice .p-voice-wrap {
  padding: 8px 0;
  position: relative;
  width: 100%;
  height: 100%;
}
.p-top-voice .p-voice-wrap::before, .p-top-voice .p-voice-wrap::after {
  content: "";
  display: block;
  width: 22px;
  height: 18px;
  position: absolute;
  z-index: 1;
}
.p-top-voice .p-voice-wrap::before {
  background: url(../img/common/icon_quotation_top.svg);
  background-size: 100% auto;
  top: 0;
  left: 15px;
}
.p-top-voice .p-voice-wrap::after {
  background: url(../img/common/icon_quotation_bottom.svg);
  background-size: 100% auto;
  bottom: 0;
  right: 15px;
}
.p-top-voice .voice-card {
  background-color: transparent;
  border-radius: 14px;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  overflow: hidden;
}
.p-top-voice .voice-card:hover {
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
  color: #333;
  opacity: 1;
}
.p-top-voice .voice-card .card-img.ratio::before {
  padding-top: 54.1666%;
}
.p-top-voice .voice-card .card-body,
.p-top-voice .voice-card .card-footer {
  background-color: white;
  padding: 20px;
}
.p-top-voice .voice-card .card-footer .days {
  color: #aaa;
}

/* ==[ .p-top-sns ]==================================== */
.p-top-sns {
  /* background-color: map_get(variables.$COLOR, gray); */
}
.p-top-sns .sec-inner {
  padding: 60px 0;
}
@media not all and (min-width: 768px) {
  .p-top-sns .sec-inner {
    padding: 40px 0;
  }
}
.p-top-sns .c-ttl-unit {
  margin-bottom: 0;
  padding: 0 30px;
}
.p-top-sns .p-yt-card-unit {
  display: flex;
  flex-direction: column;
  gap: 20px 0;
}
.p-top-sns .yt-card {
  flex-direction: row;
  text-decoration: none;
}
.p-top-sns .yt-card:hover {
  color: #333;
  opacity: 0.7;
}
.p-top-sns .yt-card .card-img {
  background-color: #333;
  width: 270px;
}
@media not all and (min-width: 992px) {
  .p-top-sns .yt-card .card-img {
    width: 160px;
  }
}
@media not all and (min-width: 768px) {
  .p-top-sns .yt-card .card-img {
    width: 270px;
  }
}
@media not all and (min-width: 578px) {
  .p-top-sns .yt-card .card-img {
    width: 100px;
  }
}
.p-top-sns .yt-card .card-img.ratio::before {
  padding-top: 65.1851%;
}
.p-top-sns .yt-card .card-img img {
  height: auto;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.p-top-sns .yt-card .card-body {
  padding: 20px 15px;
  width: calc(100% - 270px);
}
@media not all and (min-width: 992px) {
  .p-top-sns .yt-card .card-body {
    width: calc(100% - 160px);
  }
}
@media not all and (min-width: 768px) {
  .p-top-sns .yt-card .card-body {
    width: calc(100% - 270px);
  }
}
@media not all and (min-width: 578px) {
  .p-top-sns .yt-card .card-body {
    width: calc(100% - 100px);
  }
}
.p-top-sns .yt-card .card-title {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 700;
  line-height: normal;
}
.p-top-sns .p-ig-list {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media not all and (min-width: 768px) {
  .p-top-sns .p-ig-list {
    margin-top: 40px;
  }
}
.p-top-sns .p-ig-list__item {
  width: calc(33.3333333333% - 6px);
}
.p-top-sns .p-ig-list__item a {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}
.p-top-sns .p-ig-list__item a::before {
  content: "";
  display: block;
  /* padding-top: 130.435%; */
  padding-top: 100%;
}
.p-top-sns .p-ig-list__img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.p-top-sns .sns-btn {
  display: block;
  margin: 30px auto 0;
  width: 300px;
  max-width: 100%;
}
.p-top-sns .c-balloon-ttl {
  margin-top: 50px;
}
.p-top-sns .c-balloon-ttl::before {
  background-color: white;
}
.p-top-sns .sns-link-list {
  display: flex;
  gap: 0 18px;
}
@media not all and (min-width: 768px) {
  .p-top-sns .sns-link-list {
    gap: 18px;
    flex-wrap: wrap;
  }
}
.p-top-sns .sns-link-list li {
  width: 25%;
}
@media not all and (min-width: 768px) {
  .p-top-sns .sns-link-list li {
    width: calc(50% - 9px);
  }
}
.p-top-sns .sns-link-list li .sns-link {
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
}
.p-top-sns .sns-link-list li .sns-link img {
  width: 100%;
}
.p-top-sns .sns-link-list li .sns-link:hover {
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
}

/* ==[ .p-top-shop ]==================================== */
.p-top-shop .sec-inner {
  padding: 120px 0 80px;
}
@media not all and (min-width: 768px) {
  .p-top-shop .sec-inner {
    padding: 80px 0 60px;
  }
}
.p-top-shop .p-shop-card-unit {
  display: flex;
  gap: 30px 18px;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media not all and (min-width: 768px) {
  .p-top-shop .p-shop-card-unit {
    flex-wrap: wrap;
  }
}
.p-top-shop .p-card-wrap {
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  width: 33.3333333333%;
}
@media not all and (min-width: 768px) {
  .p-top-shop .p-card-wrap {
    width: 100%;
  }
}
.p-top-shop .shop-card .card-inner {
  padding: 15px 15px 30px;
}
.p-top-shop .shop-card .card-header {
  background-color: #DF121A;
  text-align: center;
}
.p-top-shop .shop-card .card-title {
  color: white;
  margin-bottom: 0;
  padding: 10px 15px;
}
.p-top-shop .shop-card .card-img {
  border-radius: 16px;
  overflow: hidden;
}
.p-top-shop .shop-card .card-img.ratio::before {
  padding-top: 67.7777%;
}
.p-top-shop .shop-card .card-img.ratio img {
  width: 100%;
  height: 100%;
}
.p-top-shop .shop-card .card-body {
  padding: 15px 0;
}
@media not all and (min-width: 768px) {
  .p-top-shop .shop-card .card-footer {
    padding: 0 20px;
  }
}
.p-top-shop .shop-card [class^=btn] + [class^=btn] {
  margin-top: 10px;
}
.p-top-shop .p-address-unit li {
  line-height: normal;
  padding-left: 26px;
  position: relative;
}
.p-top-shop .p-address-unit li::before {
  content: "";
  display: block;
  width: 16px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.p-top-shop .p-address-unit li + li {
  margin-top: 10px;
}
.p-top-shop .p-address-unit li.p-address::before {
  background: url(../img/top/shop/icon_mapping.svg) no-repeat center center;
  background-size: 100% auto;
  height: 22px;
  top: 3px;
  transform: translateY(0);
}
.p-top-shop .p-address-unit li.p-open::before {
  background: url(../img/top/shop/icon_clock.svg) no-repeat center center;
  background-size: 100% auto;
  height: 16px;
}
.p-top-shop .p-address-unit li.p-tel::before {
  background: url(../img/top/shop/icon_phone.svg) no-repeat center center;
  background-size: 100% auto;
  height: 16px;
}
.p-top-shop .p-address-unit li.p-tel a:hover {
  color: #0066C0;
  text-decoration: underline;
  opacity: 1;
}
.p-top-shop .contact-unit {
  margin-top: 30px;
}
.p-top-shop .contact-unit .contact-link {
  border-radius: 16px;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
  display: block;
  overflow: hidden;
}
.p-top-shop .contact-unit .contact-link:hover {
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
}

/* ==[ .p-top-guide ]==================================== */
.p-top-guide {
  background-color: #EDEDED;
}
.p-top-guide .sec-inner {
  padding: 60px 0;
}
@media not all and (min-width: 768px) {
  .p-top-guide .sec-inner {
    padding: 40px 0;
  }
}
.p-top-guide .ttl-main-ja {
  color: #5FA867;
}
.p-top-guide .ttl-main-ja::before {
  background: url(../img/top/guide/guide_ttl_icon.svg) no-repeat center center;
  background-size: 100% auto;
  content: "";
  display: block;
  margin: 0 auto;
  width: 35px;
  height: 55px;
}
.p-top-guide .c-contxt {
  text-align: center;
}
@media not all and (min-width: 768px) {
  .p-top-guide .c-contxt {
    text-align: left;
  }
}
.p-top-guide .p-card-block {
  margin-bottom: 50px;
}
.p-top-guide .p-card-block.type3 {
  gap: 0 18px;
}
@media not all and (min-width: 768px) {
  .p-top-guide .p-card-block.type3 {
    gap: 20px 0;
  }
}
.p-top-guide .p-card-block.type3 .c-card {
  border-radius: 16px;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.p-top-guide .p-card-block.type3 .c-card img {
  width: 100%;
}
.p-top-guide .guide-unit-top {
  display: flex;
  gap: 10px 30px;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 30px;
}
@media not all and (min-width: 992px) {
  .p-top-guide .guide-unit-top {
    gap: 10px 15px;
  }
}
@media not all and (min-width: 768px) {
  .p-top-guide .guide-unit-top {
    gap: 20px 0;
    flex-wrap: wrap;
  }
}
.p-top-guide .guide-unit-top__item {
  width: 33.3333333333%;
}
.p-top-guide .guide-unit-top__item:first-child .guide-card span::before {
  background: url(../img/top/guide/2023/guide_icon1.svg) no-repeat center center;
  background-size: 100% auto;
}
.p-top-guide .guide-unit-top__item:nth-child(2) .guide-card span::before {
  background: url(../img/top/guide/2023/guide_icon2.svg) no-repeat center center;
  background-size: 100% auto;
}
.p-top-guide .guide-unit-top__item:nth-child(3) .guide-card span::before {
  background: url(../img/top/guide/2023/guide_icon3.svg) no-repeat center center;
  background-size: 100% auto;
}
@media not all and (min-width: 768px) {
  .p-top-guide .guide-unit-top__item {
    width: 100%;
  }
}
.p-top-guide .guide-unit-top__item .guide-card {
  background-color: white;
  border-radius: 50px;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
  display: block;
  padding: 16px;
  text-align: center;
  text-decoration: none;
  width: 100%;
  /* .card-img {
    margin-bottom: 10px;

    img {
      width: auto;
    }
  } */
}
.p-top-guide .guide-unit-top__item .guide-card span {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 700;
  padding-left: 31px;
  position: relative;
}
.p-top-guide .guide-unit-top__item .guide-card span::before {
  content: "";
  display: block;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.p-top-guide .guide-unit-top__item .guide-card:hover {
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
  color: #333;
  opacity: 1;
}
.p-top-guide .guide-unit-top__item .guide-card .card-body {
  display: flex;
  flex-direction: column;
}
.p-top-guide .guide-unit-top__item .guide-card .card-title {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  line-height: normal;
  margin-bottom: 0;
}
.p-top-guide .guide-unit-bottom {
  display: flex;
  gap: 10px 20px;
  justify-content: space-between;
  flex-wrap: nowrap;
}
@media not all and (min-width: 992px) {
  .p-top-guide .guide-unit-bottom {
    gap: 10px 15px;
  }
}
@media not all and (min-width: 768px) {
  .p-top-guide .guide-unit-bottom {
    gap: 20px 0;
    flex-wrap: wrap;
  }
}
.p-top-guide .guide-unit-bottom__item {
  width: 50%;
}
.p-top-guide .guide-unit-bottom__item:first-child .guide-link span::before {
  background: url(../img/top/guide/2023/guide_icon4.svg) no-repeat center center;
  background-size: 100% auto;
}
.p-top-guide .guide-unit-bottom__item:nth-child(2) .guide-link span::before {
  background: url(../img/top/guide/2023/guide_icon5.svg) no-repeat center center;
  background-size: 100% auto;
}
@media not all and (min-width: 768px) {
  .p-top-guide .guide-unit-bottom__item {
    width: 100%;
  }
}
.p-top-guide .guide-unit-bottom__item .guide-link {
  background-color: white;
  border-radius: 50px;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
  display: block;
  padding: 16px;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
.p-top-guide .guide-unit-bottom__item .guide-link:hover {
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
  color: #333;
  opacity: 1;
}
.p-top-guide .guide-unit-bottom__item .guide-link span {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 700;
  padding-left: 31px;
  position: relative;
}
.p-top-guide .guide-unit-bottom__item .guide-link span::before {
  content: "";
  display: block;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.p-top-guide .guide-unit-bottom__item .guide-link.insurance span::before {
  background: url(../img/top/guide/guide_icon5.svg) no-repeat center center;
  background-size: 100% auto;
  transform: translateY(calc(-50% + 3px));
}
.p-top-guide .guide-unit-bottom__item .guide-link.faq span::before {
  background: url(../img/top/guide/guide_icon6.svg) no-repeat center center;
  background-size: 100% auto;
}
.p-top-guide .c-balloon-ttl::before {
  background-color: #EDEDED;
}

/* ==[ .p-top-ad ]==================================== */
.p-top-ad .sec-inner {
  padding: 60px 0;
}
@media not all and (min-width: 768px) {
  .p-top-ad .sec-inner {
    padding: 40px 0;
  }
}
.p-top-ad .swiper-container-wrapper {
  padding: 0 80px;
}
@media not all and (min-width: 768px) {
  .p-top-ad .swiper-container-wrapper {
    padding: 0;
  }
}
.p-top-ad .swiper-container-wrapper .swiper-wrapper-inner {
  overflow: hidden;
}
.p-top-ad .swiper-container-wrapper .swiper-button-prev {
  left: 0;
}
.p-top-ad .swiper-container-wrapper .swiper-button-next {
  right: 0;
}

/* ==[ .p-top-aboutus ]==================================== */
.p-top-aboutus {
  background: url(../img/top/aboutus/aboutus_bg.jpg) no-repeat center top;
  background-size: cover;
}
@media not all and (min-width: 768px) {
  .p-top-aboutus {
    background: url(../img/top/aboutus/aboutus_bg_sp.jpg) no-repeat center top;
    background-size: cover;
  }
}
.p-top-aboutus .sec-inner {
  padding: 120px 0;
}
@media not all and (min-width: 768px) {
  .p-top-aboutus .sec-inner {
    padding: 80px 0;
  }
}
.p-top-aboutus .ttl-main-en,
.p-top-aboutus .c-contxt {
  color: white;
}
.p-top-aboutus .btn-unit {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
@media not all and (min-width: 768px) {
  .p-top-aboutus .btn-unit {
    flex-direction: column;
  }
}

/* 20240711追加 */
.myBlockSearch {
  background: #ff3f05;
  background: linear-gradient(45deg, #ff3f05 0%, #df121a 80%);
  border-radius: 67px;
  margin-top: 50px;
  padding-top: 15px;
  position: relative;
}
@media not all and (min-width: 992px) {
  .myBlockSearch {
    border-radius: 40px;
  }
}
@media not all and (min-width: 768px) {
  .myBlockSearch {
    border-radius: 20px;
    margin-top: 30px;
  }
}
.myBlockSearch__outer {
  overflow: hidden;
  position: relative;
}
.myBlockSearch__header {
  margin-top: -100px;
  padding: 0 30px;
  text-align: center;
  margin-top: -55px;
  width: 100%;
  max-width: 100%;
  /* img {
    @include mixin.tabletP {
      width: 100%;
    }
  } */
}
@media not all and (min-width: 768px) {
  .myBlockSearch__header {
    margin-top: -52px;
  }
}
.myBlockSearch__inner {
  background-color: #FCE7E7;
  padding: 40px 0 70px;
}
.myBlockSearch .modFlex {
  gap: 0 32px;
  justify-content: center;
}
@media not all and (min-width: 768px) {
  .myBlockSearch .modFlex {
    flex-wrap: wrap;
    gap: 30px 0;
  }
}
.myBlockSearch .modFlex__item {
  text-align: center;
  width: 33.3333333333%;
}
@media not all and (min-width: 768px) {
  .myBlockSearch .modFlex__item {
    width: 100%;
  }
}

.myBlockFMNews {
  background-color: #333;
  color: white;
  overflow: hidden;
  position: relative;
}
.myBlockFMNews .row {
  align-items: center;
}
.myBlockFMNews__bg {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.myBlockFMNews__bg[src$=".svg"] {
  width: 100%;
  max-width: 100%;
}
.myBlockFMNews .modTitle {
  text-align: left;
}
.myBlockFMNews .modTitle__en {
  font-size: calc(30px + 20 * (100vw - 320px) / 1600);
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 800;
}
.myBlockFMNews .modTitle__ja {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 700;
}
.myBlockFMNews .modLeadText {
  margin-bottom: 50px;
}
@media not all and (min-width: 768px) {
  .myBlockFMNews .modLeadText {
    margin-bottom: 20px;
  }
}
.myBlockFMNews .btn {
  margin: 0;
}
@media not all and (min-width: 992px) {
  .myBlockFMNews .btn {
    margin: 0 auto;
  }
}
.myBlockFMNews .sec-inner {
  position: relative;
  z-index: 2;
}
.myBlockFMNews .myBlockPopup {
  flex-wrap: wrap;
  align-items: start;
  gap: 20px;
  position: relative;
}
@media not all and (min-width: 992px) {
  .myBlockFMNews .myBlockPopup {
    margin-bottom: 30px;
  }
}
.myBlockFMNews .modPopup {
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.16);
  background-color: white;
  border-radius: 12px;
  display: none;
  align-items: start;
  gap: 0 24px;
  padding: 24px 20px;
  width: 26.0416666667vw;
  max-width: 500px;
  min-width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
@media not all and (min-width: 992px) {
  .myBlockFMNews .modPopup {
    width: 50.4032258065vw;
    max-width: 500px;
    min-width: 300px;
  }
}
@media not all and (min-width: 768px) {
  .myBlockFMNews .modPopup {
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 0 15px;
    padding: 20px 15px;
    width: 100%;
    min-width: auto;
    max-width: 100%;
    position: static;
    top: auto;
    left: auto;
    transform: translate(0, 0);
  }
}
.myBlockFMNews .modPopup__img {
  text-align: center;
  width: 35%;
}
.myBlockFMNews .modPopup__img img {
  border: 1px #909090 solid;
}
.myBlockFMNews .modPopup__body {
  width: 65%;
}
.myBlockFMNews .modPopup__body .btn {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  padding: 10px;
}
.myBlockFMNews .modPopup__title, .myBlockFMNews .modPopup__text {
  color: #333;
}
.myBlockFMNews .modPopup__title {
  font-size: calc(16px + 8 * (100vw - 320px) / 1600);
}
.myBlockFMNews .modPopup__title.-new {
  display: flex;
  align-items: center;
  gap: 0 10px;
}
.myBlockFMNews .modPopup__title.-new::before {
  content: "NEW";
  background-color: #DF121A;
  color: white;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: calc(12px + 4 * (100vw - 320px) / 1600);
  font-weight: 800;
  padding: 0 5px;
}
.myBlockFMNews .modPopup__text {
  margin-bottom: 30px;
}
@media not all and (min-width: 768px) {
  .myBlockFMNews .modPopup__text {
    margin-bottom: 20px;
  }
}
.myBlockFMNews .popupCard {
  background-color: transparent;
  border: none;
  width: calc(33.3333333333% - 14px);
  transition: 0.4s ease-in-out;
}
@media not all and (min-width: 768px) {
  .myBlockFMNews .popupCard {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .myBlockFMNews .popupCard:hover {
    cursor: pointer;
  }
  .myBlockFMNews .popupCard:hover > .modPopup {
    display: flex;
  }
}
.myBlockFMNews .popupCard:nth-child(3n+1) .modPopup {
  left: 0;
  transform: translate(0, -50%);
}
@media not all and (min-width: 768px) {
  .myBlockFMNews .popupCard:nth-child(3n+1) .modPopup {
    left: auto;
    transform: translate(0, 0);
  }
}
.myBlockFMNews .popupCard:nth-child(3n) .modPopup {
  left: auto;
  right: 0;
  transform: translate(0, -50%);
}
@media not all and (min-width: 768px) {
  .myBlockFMNews .popupCard:nth-child(3n) .modPopup {
    right: auto;
    transform: translate(0, 0);
  }
}
.myBlockFMNews .popupCard .card-body {
  display: flex;
  gap: 10px 0;
  justify-content: center;
  flex-direction: column;
}
@media not all and (min-width: 768px) {
  .myBlockFMNews .popupCard .card-body {
    display: none;
  }
}
.myBlockFMNews .popupCard .card-img {
  border: 1px #909090 solid;
}
.myBlockFMNews .popupCard .card-title {
  font-size: calc(14px + 8 * (100vw - 320px) / 1600);
  text-align: center;
}
.myBlockFMNews .popupCard .card-title::before {
  content: "";
  display: inline-block;
}
.myBlockFMNews .popupCard .card-title.-new {
  display: flex;
  align-items: center;
  gap: 0 5px;
}
.myBlockFMNews .popupCard .card-title.-new::before {
  content: "NEW";
  background-color: #DF121A;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: calc(12px + 2 * (100vw - 320px) / 1600);
  font-weight: 800;
  padding: 0 4px;
  min-width: calc(46px + 6 * (100vw - 320px) / 1600);
}